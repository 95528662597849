<template>
  <div class="headerbox">
    <div class="header">
      <div class="headerL">
        <a onclick="javascript:history.back(-1)" class="goback"><img src="images/goback.png" /></a>
      </div>
      <div class="headerC">
        <p>个人资料</p>
      </div>
      <div class="headerR"></div>
    </div>
  </div>
  <div class="clear"></div>
  <div class="hbox"></div>

  <div class="clear"></div>
  <div class="jsdingdan">
    <div class="jsyf" @click="options.isLogoShow = true" >
      <div class="jsyfL">
        <p>头像：</p>
      </div>
      <div class="jsyfR" ><img :src="(options.item.logo==null||options.item.logo=='')?'images/tx.png':options.item.logo.replace('/http://','http://') " /></div>
    </div>
    <div class="jsyf" @click="options.isNickNameShow = true">
      <div class="jsyfL">会员名：</div>
      <div class="jsyfR" style="color:#999">{{ options.item.name }}</div>
    </div>
    <div class="jsyf" @click="options.isPhoneShow = true">
      <div class="jsyfL">手机：</div>
      <div class="jsyfR" style="color:#999">{{ options.item.phone
      }}</div>
    </div>
    <div class="jsyf">
      <div class="jsyfL">性别：</div>
      <div class="jsyfR" style="color:#999">{{ options.item.sex == 1 ? '女' : '男' }}</div>
    </div>
  </div>
  <van-popup v-model:show="options.isNickNameShow" position="bottom" :style="{ height: '30%' }">
    <div id="size" style="bottom: 0px;">
      <div class="size2">
        <div class="size2_1">
          <van-cell-group inset>
            <van-field v-model="paramsUpdate.name" label="会员名" placeholder="请输入用会员名" />
          </van-cell-group>
        </div>
      </div>
      <div class="size3">
        <a href="javascript:;" @click="updateNickName">修改</a>
      </div>
    </div>
  </van-popup>
  <van-popup 
  v-model:show="options.isPhoneShow" 
  position="bottom" 
  :close-on-click-overlay="false"
  :style="{ height: '42%' }">
    <div style="font-size:1.8rem;">手机号：{{paramsUpdate.phone}}<p style="width: 5%;float: right;" @click="options.isPhoneShow=false">x</p></div>
    <van-number-keyboard v-if="options.isPhoneShow" v-model="paramsUpdate.phone" theme="custom"
      :hide-on-click-outside="true" close-button-text="完成" :show="options.isPhoneShow"
      @blur="updatePhone" />
  </van-popup>
  <van-popup
    v-model:show="options.isLogoShow"
    :style="{ height: '40vh' }"
    position="bottom"
  >
    <van-divider
      :style="{ color: '#ff2150', borderColor: '#ff2150', padding: '0 16px' }"
    >
      修改Logo
    </van-divider>
    <img
      @click="options.isLogoShow = false"
      src="images/close.png"
      style="float: right; margin-top: -3rem; width: 1rem"
    />
    <van-field name="uploader" label="Logo上传">
      <template #input>
        <van-uploader
          style="float: right"
          :after-read="updateLogo"
          multiple
          :max-count="1"
        />
        <van-image width="100" :src="paramsUpdate.logo" />
      </template>
    </van-field>
    <div class="sx_3">
      <a href="javascript:;" @click="uploadLogo">修改</a>
    </div>
  </van-popup>
</template>

<script>
import memberModel from '@/model/memberModel'
export default {
  name: 'memberInfoPage',
  setup() {
    const { options, paramsUpdate, getInfo, updateLogo,uploadLogo, updateNickName,
      updatePhone } = memberModel();
    getInfo();
    return {
      options,
      paramsUpdate,
      updateNickName,
      updatePhone,
      updateLogo,
      uploadLogo
    }
  }
};
</script>

<style></style>